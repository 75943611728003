import React from "react";
import MedicalImg from "../images/vaccination1.jpg";

const Medical = () => {
  return (
    <div className="flex flex-col items-center rounded-xl border border-green-500 px-4">
      <p className="text-3xl text-green-800 font-bold my-10">Medical</p>
      <div className="overflow-hidden my-1 rounded-lg">
        <img
          src={MedicalImg}
          alt=""
          className="object-cover h-72 w-96 hover:scale-105"
        />
      </div>
      <p className="text-justify my-12">
        <span className="text-green-500">Panini School</span> vows to implement
        health care plans for students and staffs with special health care
        needs, healthy school environment and healthy education. The objectives
        of Panini school health are to improve student’s achievement with sound
        health skills. We provide health counseling, emergency care for injury
        or illness to ensure that all students get appropriate vigilance and for
        these consequences we have a separate nursing department with trained
        nurse for supervision and hospitality. We also provide health insurance
        facilities which is a mandatory to every member of school.
      </p>
    </div>
  );
};

export default Medical;
