import React from "react";
import Sankha from "../images/sankha.JPG";
import Karate from "../images/karate.JPG";
import Violin from "../images/violin.JPG";
import Pe from "../images/pe.JPG";

const BeyondAcademics = () => {
  return (
    // title section
    <div className="container my-20 p-10 mx-auto bg-orange-100">
      <h2 class="font-heading text-center my-10 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
        WHY PANINI?
      </h2>
      <p className="font-semibold text-xl mb-14 justify-center text-center leading-10 md:mx-28 sm:mx-3">
        Join us for a holistic educational experience that combines the
        understanding of Eastern culture, norms, and values with the discipline
        of a traditional academic environment. Here, students not only delve
        into the rich tapestry of Eastern culture but also learn the importance
        of respect, harmony, and community values deeply rooted in this
        heritage. Furthermore, they are exposed to rigorous academic programs
        infused with Western technological advancements, ensuring they are
        equipped with the necessary skills to thrive in today's interconnected
        world. This unique blend of Eastern wisdom and Western academic
        excellence fosters well-rounded individuals capable of navigating
        diverse cultural landscapes while excelling in their academic pursuits.
      </p>

      {/* photos section */}
      <div className="grid md:grid-cols-2 sm:grid-cols-1 mb-5">
        <img
          src={Sankha}
          className=" object-cover hover:scale-105 m-5 mx-auto rounded-2xl border-orange-300 border-8"
          width={450}
          alt="student playing sankha"
        />
        <img
          src={Karate}
          className="object-cover hover:scale-105 m-5 mx-auto rounded-2xl border-green-600 border-8"
          width={450}
          alt="students practising karate"
        />
        <img
          src={Violin}
          className="object-cover hover:scale-105 m-5 mx-auto rounded-2xl border-green-600 border-8"
          width={450}
          alt="students playing the violin"
        />
        <img
          src={Pe}
          className="object-cover hover:scale-105 m-5 mx-auto rounded-2xl border-orange-400 border-8"
          width={450}
          alt="students doing physical exercise"
        />
      </div>

      <h2 className="text-orange-400 font-semibold text-xl m-4">
        {" "}
        1. Extra Curricular Activities (ECA) for Overall Development:
      </h2>
      <p className="m-4 leading-8">
        Panini School recognizes the importance of going beyond textbooks for
        the comprehensive development of each student. Our Extra-Curricular
        Activities (ECA) programs are meticulously designed to ensure the
        students evolve into well-rounded individuals, fostering qualities that
        go beyond traditional academic learning.{" "}
      </p>

      <h2 className="text-orange-400 font-semibold text-xl m-4">
        {" "}
        2. Regular Mass Speaking:
      </h2>
      <p className="m-4 leading-8">
        As a crucial aspect of out holistic approach, students engage in regular
        public speaking sessions, addressing a mass audience daily. This
        initiative not only aims to develop their confidence but also serves as
        a proactive measure against xenophobia. By encouraging students to
        express themselves in front of others, we instill vital communication
        and presentation skills that are essential for their personal and
        professional growth.
      </p>

      <h2 className="text-orange-400 font-semibold text-xl m-4">
        {" "}
        3. Rehearse (Voice Therapy):
      </h2>
      <p className="m-4 leading-8">
        Voice therapy at Panini School goes beyond making a child a proficient
        vocalist; it focuses on enhancing clarity in voice during conversations
        and speeches. This initiative acknowledges the importance of effective
        communication skills in various contexts, preparing studetns to
        articulate their thoughts with confidence and precision.
      </p>

      <h2 className="text-orange-400 font-semibold text-xl m-4">
        {" "}
        4. Sports-Friendly Environment:
      </h2>
      <p className="m-4 leading-8">
        Panini school takes pride in being a sports-friendly institution,
        providing a wide range og facilities for students to engage in games and
        sports regularly. Our programs are strategically designed to take pace
        in the evenings after regular school activities, ensuring that every
        student actively participates in physical activities. This commitment
        not only promotes physical well-being but also fosters teamwork,
        discipline, and a spirit of healthy competition.
      </p>
    </div>
  );
};

export default BeyondAcademics;
