import React from 'react'
import Forms from '../Form.jsx'

const AdmissionForm = () => {
  return (
    <div>
      <Forms/>
    </div>
  )
}

export default AdmissionForm
