import React from 'react';

function ColoredHashtags({ text }) {
  const coloredText = text.split(' ').map((word, index) => {
    if (word.startsWith('#')) {
      return (
        <span key={index} style={{ color: 'blue' }}>
          {word}{' '}
        </span>
      );
    } else {
      return <span key={index}>{word} </span>;
    }
  });

  return <p style={{ textIndent: "60px" }}>{coloredText}</p>;
}

export default ColoredHashtags;
