import React from "react";
import ColoredHashtags from "./Hastags";
import EventItems from "./EventItems";

const Event = () => {
  return (
    <div>
      {EventItems.map((item, index) => (
        <div key={index} className="container mx-auto my-20 md:my-32 border border-amber-500 rounded-lg">
          <div className="grid grid-flow-col">
            <div className="col-span-1">
              <div className="container bg-[#005916aa] text-center py-3">
                <p className="text-xl text-white">{item.event_date.day}</p>
                <p className="text-xl text-white pb-3">
                  {item.event_date.month}
                </p>
                <p className="text-xl text-white">{item.event_date.year}</p>
              </div>
            </div>
            <div className="col-span-11 flex items-center border-b-2">
              <p className="text-2xl ms-4 font-semibold">{item.event_title}</p>
            </div>
          </div>
          <div className="container ps-32 pe-20 mt-8 mb-4">
            <ColoredHashtags text={item.event_description} />
          </div>
          <div className="p-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
            {item.event_photos.map((src, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-lg shadow-lg"
              >
                <img
                  src={src}
                  alt="event"
                  className="h-full w-full object-cover hover:scale-125"
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Event;