import React, { useState } from "react";
import { Link } from "react-router-dom";
import AboutDropdown from "./AboutDropdown";
import InfoDropdown from "./InfoDropdown";

import Logo from "../../images/logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [infoDropdown, setInfoDropdown] = useState(false);
  const [aboutclick, setAboutClick] = useState(false);
  const [infoclick, setInfoClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const handleAboutMouseEnter = () => {
    if (window.innerWidth < 960) {;
      setAboutDropdown(false);
    } else {
      setAboutDropdown(true);
    }
  };

  const handleAboutMouseLeave = () => {
    if (window.innerWidth < 960) {
      setAboutDropdown(false);
    } else {
      setAboutDropdown(false);
    }
  };

  const handleInfoMouseEnter = () => {
    if (window.innerWidth < 960) {
      setInfoDropdown(false);
    } else {
      setInfoDropdown(true);
    }
  };

  const handleInfoMouseLeave = () => {
    if (window.innerWidth < 960) {
      setInfoDropdown(false);
    } else {
      setInfoDropdown(false);
    }
  };

  const handleAboutClick = () => {
    if (window.innerWidth < 960) {
      setAboutClick(!aboutclick);
    }
  }

  const handleInfoClick = () => {
    if (window.innerWidth < 960) {
      setInfoClick(!infoclick);
    }
  }
  return (
    <div className="sticky top-0 z-50 bg-white">
      <nav className="navbar flex shadow-md">
        <Link to="/" className="navbar-logo p-4">
          <img src={Logo} alt="logo" className="h-24 object-cover" />
        </Link>
        <div className="name text-green nimbus-roman">
          <p className="uppercase text-3xl">Panini School</p>
          <p className="text-sm">A good education equips one for life.</p>
        </div>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li
            className="nav-item nav-links"
            onMouseEnter={handleAboutMouseEnter}
            onMouseLeave={handleAboutMouseLeave}
            onClick={handleAboutClick}
          >
            About
            <i className="hidden md:block fas fa-caret-down ms-2" />
            {(aboutDropdown || aboutclick) && <AboutDropdown setClick={setClick} />}
          </li>
          <li
            className="nav-item nav-links"
            onMouseEnter={handleInfoMouseEnter}
            onMouseLeave={handleInfoMouseLeave}
            onClick={handleInfoClick}
          >
            Information
            <i className="hidden md:block fas fa-caret-down ms-2" />
            {(infoDropdown || infoclick) && <InfoDropdown setClick={setClick} />}
          </li>
          <li className="nav-item">
            <Link to="/specialities" className="nav-links" onClick={closeMobileMenu}>
              Specialities
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/events" className="nav-links" onClick={closeMobileMenu}>
              Events
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/gallery" className="nav-links" onClick={closeMobileMenu}>
              Gallery
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/form" className="nav-links" onClick={closeMobileMenu}>
              Admission
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
