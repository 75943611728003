import React from 'react'
import OurGoalsComp from '../OurGoals'


const OurGoals = () => {
  return (
    <div>
    <OurGoalsComp/>
    </div>
  )
}

export default OurGoals
