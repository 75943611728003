import React from "react";

const parichaya = () => {
  return (
    <div className="container my-20 p-10 mx-auto bg-orange-50">
      <h2 className="font-heading text-center my-10 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
        परिचय
      </h2>
      <p className="font-semibold text-base mb-14 justify-center text-center leading-10 md:mx-28 sm:mx-3">
        वि.सं. २०६० सालमा स्थापित यो पाणिनि स्कुल चिरकालदेखि शिक्षाक्षेत्रमा
        समर्पित एवं सुपरिचित शिक्षाविद्हरूको निर्देशन र नेतृत्वमा सञ्चालित
        विद्यालय हो । औपचारिक शिक्षा मात्र नभई सी.टी.ई.भी.टी.बाट सम्बन्धनप्राप्त
        पेन्टागन टेक्निकल कलेज र क्याम्ब्रिज कलेज अफ इञ्जिनियरिङमार्फत्
        प्राविधिक शिक्षा समेत प्रदान गरी दक्ष जनशक्ति उत्पादन गर्नु पनि यस
        शैक्षिक संस्थाको लक्ष्य रहेको छ । यो शैक्षिक संस्था कागेश्वरी मनोहरामा
        अवस्थित बाह्र रोपनी जग्गामा फैलिएको छ । भूकम्पप्रतिरोधी शैक्षणिक भवन एवं
        स्वच्छ, शान्त र रमणीय वातावरणसहितका प्रशस्त भौतिक पूर्वाधारहरूले सम्पन्न
        यस विद्यालयमा बालमैत्रीपूर्ण वातावरणमा शिक्षणकार्य गरिन्छ । आफ्ना भावी
        सन्ततिका लागि सकारात्मक ऊर्जाका साथ भौतिक संसारको सम्यक् बोध गराउने
        खालको शैक्षिक व्यवस्था गर्नु आधुनिक शिक्षाको अर्को महत्त्वपूर्ण पक्ष
        भएकोमा पाणिनि स्कुल निःसन्देह छ । शिक्षाक्षेत्रका विशिष्ट महानुभावहरूको
        व्यक्तिगत संलग्नता एवं योग्य शिक्षणसमूहको निरन्तर सहकार्यलाई यस
        विद्यालयले आफ्नो सफलताको कडी मानेको छ । यस विद्यालयको शिक्षणसमूह
        विद्यालयको वार्षिक शैक्षिक योजनानुसारको अध्यापनका साथमा अतिरिक्त
        क्रियाकलापमा छात्रवर्गको अनिवार्य सहभागिताका लागि समेत प्रतिबद्ध छ ।
        विद्यार्थीहरूलाई सामुदायिक कार्य, वृक्षारोपण, शैक्षिक भ्रमण, दैनिक
        योगकक्षा, संगीत, नृत्य, काष्ठकला, चित्रकला र खेलकूद (ब्याडमिन्टन, फुटबल,
        भलिबल, बास्केटबल, टेबलटेनिस) आदिमा अभ्यस्त गराउनुका साथै जीवनोपयोगी
        विविध सीपहरूको ज्ञान दिने व्यवस्था समेत पाणिनि विद्यालयले गरेको छ ।
        विद्यार्थीको समग्र हित एवं सर्वाङ्गीण विकासलाई ध्यानमा राखेर
        विद्यालयपरिसरमै व्यवस्थित पुस्तकालय, अत्याधुनिक कम्प्युटरल्याब, फुटसल,
        क्रिकेट–मैदान तथा स्वच्छ र स्वास्थ्यानुकूल चमेनागृहको व्यवस्था गरिएको छ
        । विद्यार्थीहरूलाई पूर्ण रूपमा शैक्षिक परिवेश दिनका लागि अर्ध र पूर्ण
        आवासीय सुविधाको समेत पाणिनिले व्यवस्था मिलाएको छ । विद्यार्थीहरूको
        प्रभावकारी शिक्षण एवं अतिरिक्त क्रियाकलापको समेत पूर्ण अभ्यासका लागि
        पाणिनिले सबैका लागि अनिवार्य दिवा–आवासीय शिक्षणको सिद्धान्तलाई आत्मसात्
        गरेको छ । यस्तो व्यवस्थाबाट मात्र समग्रतया दक्ष जनशक्तिको उत्पादन सुकर
        हुने कुरामा हाम्रो विश्वास छ ।
      </p>
    </div>
  );
};

export default parichaya;
