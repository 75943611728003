import React from "react";
import TransportImg from "../images/transport.jpg";

const Transport = () => {
  return (
    <div className="flex flex-col items-center rounded-xl border border-green-500 px-4">
      <p className="text-3xl text-green-800 font-bold my-10">Transport</p>
      <div className="overflow-hidden my-1 rounded-lg">
        <img
          src={TransportImg}
          alt=""
          className="object-cover h-72 w-96 hover:scale-105"
        />
      </div>
      <p className="text-justify my-12">
        Transporting a large group of kids is no easy task, especially when we
        the responsibility of every child’s safety and comfort. Everyone of our
        buses has an experienced driver, a conductor and (a governor’s on board
        for pre-school children). Children are helped into and off the bus at
        all times. Attendance is taken on the bus to ensure that everyone on the
        route has found their way to the right bus. The school aims to design a
        highly standard and comfort transport system to ensure that young one
        has a wonderful time coming to school and going home.School appreciates
        child/children to be accompanied by their parents/guardians even when
        they find school at their shortest distance. However parents residing
        further distance are encouraged to use school transport system in terms
        of security and safety. School appreciates child/children to be
        accompanied by their parents/guardians even when they find school at
        their shortest distance. However, children residing further distance are
        encouraged to use school transport for safety and security purpose.
        Eventually we believe transporting a large group of kids is not an easy
        task, especially when we shoulder the responsibility of every child’s
        safety and comfort. Our buses have an experienced driver a conductor and
        (a governess on board for pre-school children). Children are helped into
        and off the bus at all times. Attendance is taken on the bus to ensure
        that everyone on the route has found their way to the right bus. The
        school aims to design a high standard and comfort transport system to
        ensure that young one has a wonderful time coming to school and going
        home.
      </p>
    </div>
  );
};

export default Transport;
