import React from 'react'
import BeyondAcademics from '../BeyondAcademics'

const WhyPanini = () => {
  return (
    <div>
      <BeyondAcademics/>
    </div>
  )
}

export default WhyPanini
