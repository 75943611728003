import React from "react";

const OurGoalsComp = () => {
  return (
    <div className="container mt-20 p-10 mx-auto mb-10 bg-orange-100">
      <h2 class="font-heading text-center my-20 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
        OUR GOALS
      </h2>

      <ul className="list-disc text-xl leading-10 md:ms-64 sm:ms-1">
        <li>Develop every student’s potential.</li>
        <li>
          Nurture a life-long love of learning to participate and showcase.
        </li>
        <li>Create an exciting environment</li>
        <li>
          Encourage independent thinking, prepare every child to make a
          difference.
        </li>
        <li>Provide opportunities to reflect on life and learning.</li>
        <li>Ensure everyone has a voice and an opportunity to be heard.</li>
        <li>Encourage good health and well-being for all.</li>
        <li>Develop culture and a sense of respect towards every culture.</li>
        <li>Provide opportunities that challenge.</li>
        <li>Respect thoughts of every person and trace out the best.</li>
        <li>Develop a feeling of brotherhood and a nature lover.</li>
        <li>A sense to respect self and other’s property.</li>
        <li>
          Respect local values, tolerance of other races, religions and the ways of
          life.
        </li>
      </ul>
    </div>
  );
};

export default OurGoalsComp;
