import React from 'react'
import { NoteChairpersonMain } from '../NoteChairperson'
import { NotePrincipalMain } from '../NotePrincipal'

const Message = () => {
  return (
    <div>
      <NotePrincipalMain />
      <NoteChairpersonMain />
    </div>
  )
}

export default Message