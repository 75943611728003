import React, { useState, useEffect } from "react";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={scrollToTop}
      style={{
        display: isVisible ? "block" : "none",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "999",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="green"
        viewBox="0 0 16 16"
        transform="rotate(180)"
      >
        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.354 5.646a.5.5 0 0 0-.708-.708L8 8.293 5.354 5.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3z" />
      </svg>
    </button>
  );
};

export default ScrollToTopButton;
