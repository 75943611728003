import React from "react";
import LibraryImg from "../images/library.jpg";

const Library = () => {
  return (
    <div className="flex flex-col items-center rounded-xl border border-green-500 px-4">
      <p className="text-3xl text-green-800 font-bold my-10">Library</p>
      <div className="overflow-hidden my-1 rounded-lg">
        <img
          src={LibraryImg}
          alt=""
          className="object-cover h-72 w-96 hover:scale-105"
        />
      </div>
      <p className="text-justify my-12">
        Library of <span className="text-green-500">Panini School</span> is a
        corner-store for healthy community to benefit the learners/explorers.
        Various books, reference materials are introduced to the maximum for the
        readers in a full-fledged digital library . The students can be equipped
        with unique and valuable resources to exhibit their learning and reading
        skills and showcase the positive impact on the academics. To enhance
        effective teaching and learning,{" "}
        <span className="text-green-500">Panini School</span>, Panini School strongly
        facilitate the students along with their involvement in E-Learning ,
        which is a theory that describes the cognitive science principles of
        effective multimedia learning using electronic educational technology.
        We believe that, selection of appropriate multimedia modalities enhance
        their learning. we also believe that children learn best when they
        understand how to learn! We develop Children’s learning power by making
        the teaching environment more interesting and relevant that helps them
        from being passive learners to active and independent learners. We have
        designed twenty minutes reading and learning session for students
        everyday in an alternative period throughout the week and has been named
        ‘My Dear Time’.
      </p>
    </div>
  );
};

export default Library;
