import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/pages/Home";
import About from "./components/AboutUs";
import Fees from "./components/pages/Fees";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import TopHeader from "./components/TopHeader";
import Message from "./components/pages/Message";
import WhyPanini from "./components/pages/WhyPanini";
import OurGoals from "./components/pages/OurGoals";
import AdmissionForm from "./components/pages/AdmissionForm";
import Gallery from "./components/pages/Gallery";
import OurSpecialities from "./components/pages/OurSpecialities";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Navbar from "./components/navbar/Navbar";
import Events from "./components/pages/Events";
import NotFound from "./components/NotFound";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <TopHeader />
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about/whoweare" element={<About />} />
          <Route path="/about/message" element={<Message />} />
          <Route path="/about/whypanini" element={<WhyPanini />} />
          <Route path="/about/ourgoals" element={<OurGoals />} />
          <Route path="/information" element={<Fees />} />
          <Route path="/information/fees" element={<Fees />} />
          <Route path="/specialities" element={<OurSpecialities />} />
          <Route path="/events" element={<Events />} />
          <Route path="/gallery" element={<Gallery />}/>
          <Route path="/form" element={<AdmissionForm/>}/>
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <ScrollToTopButton />
      </BrowserRouter>
    </div>
  );
}

export default App;
