export const AboutMenuItems = [
  {
    title: "Who We Are",
    path: "/about/whoweare",
    cName: "dropdown-link",
  },
  {
    title: "Why Panini",
    path: "/about/whypanini",
    cName: "dropdown-link",
  },
  {
    title: "Our Goals",
    path: "/about/ourgoals",
    cName: "dropdown-link",
  },
  {
    title: "Messages",
    path: "/about/message",
    cName: "dropdown-link",
  }
];

export const InfoMenuItems =[ {
    title: "Fee Structure",
    path: "/information/fees",
    cName: "dropdown-link",
  }
];
