import React from "react";
import CafeteriaImg from "../images/cafeteria.jpg";

const Cafeteria = () => {
  return (
    <div className="flex flex-col items-center rounded-xl border border-green-500 px-4">
      <p className="text-3xl text-green-800 font-bold my-10">
        Cafeteria
      </p>
      <div className="overflow-hidden my-1 rounded-lg">
        <img
          src={CafeteriaImg}
          alt=""
          className="object-cover h-72 w-96 hover:scale-105"
        />
      </div>
      <p className="text-justify my-12">
        When children don’t eat a healthy lunch, its harder for them to muster
        the energy for school activities. So it’s vital your child eats healthy
        meals as it ensure health and well being, improves academic performance,
        prevents obesity, stay focused, pay attention in class and learn the
        information presented to them in their class and after school
        activities. Therefore it is important to ensure well balanced meals So,
        that your child receives good nutrition.Since Panini runs semi
        residential and full boarding system in its holding company it has left
        no stone unturned and never compromise to provide the healthy and
        hygienic food to semi residential students and staff and 5 times to
        boarding school students a day including seasonal fruits and milk at an
        affordable cost.
      </p>
    </div>
  );
};

export default Cafeteria;
