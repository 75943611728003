import React from "react";
import CharipersonImg from "../images/chairperson.jpeg";
import Quote from "../images/quote.svg";
import { Link } from "react-router-dom";

export const NoteChairpersonMain = () => {
  return (
    <div className="container mx-auto my-20 px-8">
      <div className="grid sm:grid-cols-1 md:grid-cols-2 bg-orange-100 rounded-xl overflow-hidden">
        <div className="mx-auto my-auto px-4 hidden md:block">
          <img
            src={CharipersonImg}
            alt="Chairperson"
            className="object-cover rounded-3xl"
          />
        </div>
        <div className="message">
          <div className="bg-yellow-500 pt-16 pb-4 ms-auto">
            <p className="text-4xl font-bold text-center mb-4 uppercase">
              Message from Chairperson
            </p>
            <p
              className="text-2xl text-center text-white"
              style={{ fontFamily: '"Brush 455 W03 Rg"' }}
            >
              Mr. Badri Bahadur Karki
            </p>
            <p className="text-sm text-center">
              Former Acting Auditor General <br />
              Chairman
            </p>
          </div>
          <div className="mx-auto my-auto p-4 md:hidden sm:block">
            <img
              src={CharipersonImg}
              alt=""
              className="object-cover rounded-3xl"
            />
          </div>
          <div className="text-justify leading-7 mb-12 pe-4 ps-4 md:ps-0">
            <span className="text-xl font-bold leading-10 pb-4">
              Dear parents/guardians,
            </span>
            <br />
            It is my pleasure to extend a warm welcome to you on behalf of
            Panini School. At Panini, we take pride in our commitment to
            providing a holistic education that not only nurtures academic
            excellence but also celebrates our rich cultural heritage. <br />
            Our school places a special emphasis on Sanskrit culture, weaving
            its timeless traditions into our curriculum. We believe that an
            understanding of Sanskrit culture is essential for cultivating
            values, ethics, and a deep sense of identity. In line with this
            commitment, we integrate the teachings of the Bhagavad Gita, a
            spiritual and philosophical masterpiece, into our educational
            framework. The Bhagavad Gita serves as a guiding light, imparting
            invaluable life lessons that go beyond the academic realm. <br />
            At Panini School, we invite you to join us on this journey of
            academic excellence infused with the wisdom of Sanskrit culture and
            the Bhagavad Gita. Together, let's nurture minds, build character,
            and create a future generation rooted in knowledge and tradition.{" "}
            <br />
            Warm regards,
          </div>
        </div>
      </div>
    </div>
  );
};

export const NoteChairpersonSmall = () => {
  return (
    <div className="container my-20 mx-auto px-4 md:px-36">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="rounded-xl overflow-hidden md:mx-10 block md:hidden">
          <img
            src={CharipersonImg}
            alt="Principal"
            className="object-cover h-96 w-full"
          />
        </div>
        <div className="text">
          <img src={Quote} alt="Quote" className="h-12 mb-6" />
          <span className="text-xl leading-10">Dear parents/guardians,</span>
          <p className="text-justify my-6 leading-9">
          It is my pleasure to extend a warm welcome to you on behalf of
            Panini School. At Panini, we take pride in our commitment to
            providing a holistic
            ...
          </p>
          <Link to="/about/message">
            <div className="flex items-center text-red-500">
              <p className="text-lg">Read More</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-5 ps-4"
              >
                <path
                  fill="#ff0000"
                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                />
              </svg>
            </div>
          </Link>
          <p className="text-2xl" style={{ fontFamily: '"Brush 455 W03 Rg"' }}>
            Mr. Badri Bahadur Karki
          </p>
          <p className="italic text-lg">
            Former Acting Auditor General <br />
            Chairman
          </p>
        </div>
        <div className="rounded-xl overflow-hidden md:mx-10 md:block hidden">
          <img
            src={CharipersonImg}
            alt="Principal"
            className="object-cover h-96 w-full"
          />
        </div>
      </div>
    </div>
  );
};
