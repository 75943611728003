import React from "react";

const form = () => {
  return (
    <div className="mx-auto bg-orange-100 flex justify-centers">
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfDVD3WyHP8fxeYIR3iMTdDESc9VxxY4peZSG-_H3pgrWCAqQ/viewform?embedded=true"
        width="1200"
        height="600"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
        className="mx-auto"
        title="google_form"
      >
        Loading…
      </iframe>{" "}
    </div>
  );
};

export default form;
