import React from "react";
import PrincipalImg from "../images/principal.jpeg";
import Quote from "../images/quote.svg";
import { Link } from "react-router-dom";

export const NotePrincipalMain = () => {
  return (
    <div className="container mx-auto my-20 px-8">
      <div className="border-2 border-solid border-green-700 p-4 rounded-xl bg-gradient-to-tr from-30% from-transparent to-70% to-orange-50">
        <h1 className="text-4xl font-bold text-center mb-4 uppercase">
          Message from Director/ Principal
        </h1>
        <h3
          className="text-2xl text-center text-yellow-500"
          style={{ fontFamily: '"Brush 455 W03 Rg"' }}
        >
          Mr. Prakash Chandra Khadka
        </h3>
        <div className="grid sm:grid-cols-1 md:grid-cols-2">
          <div className="mx-auto my-auto sm:block md:hidden">
            <img
              src={PrincipalImg}
              alt="Principal"
              className="objet-cover rounded-3xl"
            />
          </div>
          <div className="message text-justify leading-7">
            <span className="text-xl font-bold leading-10">
              Dear parents/guardians,
            </span>{" "}
            <br />
            <span className="text-lg font-semibold leading-10">
              Namo Namaha!
            </span>{" "}
            <br />
            As the Principal and Director of Panini School, I am delighted to
            present our website, encapsulating the essence of our educational
            philosophy. At Panini, we are dedicated to fostering a learning
            environment that inspires curiosity, critical thinking, and holistic
            development. Our committed faculty, cutting-edge facilities, and a
            curriculum designed to meet global standards underscore our
            commitment to nurturing well-rounded individuals. Panini School
            stands as a beacon of educational excellence, striving to instill
            values, ignite creativity , and equip students with the skills
            necessary for a dynamic and ever-changing world. We believe in
            creating a supportive community where each student is encouraged to
            explore their potential and embrace a love for learning. <br />I
            invite you to explore the pages of our website, offering insights in
            to myriad opportunities and experiences awaiting your child at
            Panini School. Together, let's embark on a journey of educational
            enrichment , character development, and success. Thank you for
            considering Panini School as a partner in your child's academic
            journey.
          </div>
          <div className="mx-auto my-auto sm:hidden md:block">
            <img
              src={PrincipalImg}
              alt="Principal"
              className="objet-cover ps-4 rounded-3xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const NotePrincipalSmall = () => {
  return (
    <div className="container my-20 mx-auto px-4 md:px-36">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="rounded-xl overflow-hidden md:mx-10">
          <img
            src={PrincipalImg}
            alt="Principal"
            className="object-cover h-96 w-full"
          />
        </div>
        <div className="text">
          <img src={Quote} alt="Quote" className="h-12 mb-6" />
          <span className="text-xl leading-10">Dear parents/guardians,</span>
          <p className="text-justify my-6 leading-9">
            As the Principal and Director of Panini School, I am delighted to
            present our website, encapsulating the essence of our educational
            ...
          </p>
          <Link to="/about/message">
            <div className="flex items-center text-red-500">
              <p className="text-lg">Read More</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                className="h-5 ps-4"
              >
                <path
                  fill="#ff0000"
                  d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                />
              </svg>
            </div>
          </Link>
          <p
            className="text-2xl"
            style={{ fontFamily: '"Brush 455 W03 Rg"' }}
          >
            Mr. Prakash Chandra Khadka
          </p>
          <p className="italic text-lg">Principal</p>
        </div>
      </div>
    </div>
  );
};
