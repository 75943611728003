import React from "react";
import SimpleSlider from "../Carousel";
import OurStrength from "../OurStrength";
import { NotePrincipalSmall } from "../NotePrincipal";
import { NoteChairpersonSmall } from "../NoteChairperson";
import Parichaya from "../Parichaya";
import SchoolImg from "../../images/school.jpg";

const Home = () => {
  return (
    <div>
      <img
        src={SchoolImg}
        className="h-200 w-full object-cover block md:hidden"
        alt=""
      />
      <div className="hidden md:block">
        <SimpleSlider />
      </div>
      <Parichaya />
      <NotePrincipalSmall />
      <OurStrength />
      <NoteChairpersonSmall />
    </div>
  );
};

export default Home;
