import React from "react";
import FeeTable from "../FeeTable";

const Fees = () => {
  const pg = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "8500" },
    { particulars: "Monthly Fee", fees: "9500" },
  ];

  const lkg = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "8500" },
    { particulars: "Monthly Fee", fees: "10500" },
  ];

  const ukg = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "8500" },
    { particulars: "Monthly Fee", fees: "11500" },
  ];

  const one = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "9000" },
    { particulars: "Monthly Fee", fees: "15500" },
  ];

  const two = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "9000" },
    { particulars: "Monthly Fee", fees: "16000" },
  ];

  const three = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "9000" },
    { particulars: "Monthly Fee", fees: "16500" },
  ];

  const four = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "9000" },
    { particulars: "Monthly Fee", fees: "17000" },
  ];

  const five = [
    { particulars: "Admission Fee", fees: "9500" },
    { particulars: "Registration Fee", fees: "9500" },
    { particulars: "Annual Fee", fees: "9500" },
    { particulars: "Maintenance", fees: "9000" },
    { particulars: "Monthly Fee", fees: "17500" },
  ];

  const six = [
    { particulars: "Admission Fee", fees: "10000" },
    { particulars: "Registration Fee", fees: "10000" },
    { particulars: "Annual Fee", fees: "10000" },
    { particulars: "Maintenance", fees: "10000" },
    { particulars: "Monthly Fee", fees: "18000" },
  ];

  const seven = [
    { particulars: "Admission Fee", fees: "10000" },
    { particulars: "Registration Fee", fees: "10000" },
    { particulars: "Annual Fee", fees: "10000" },
    { particulars: "Maintenance", fees: "10000" },
    { particulars: "Monthly Fee", fees: "18500" },
  ];

  const eight = [
    { particulars: "Admission Fee", fees: "10000" },
    { particulars: "Registration Fee", fees: "10000" },
    { particulars: "Annual Fee", fees: "10000" },
    { particulars: "Maintenance", fees: "10000" },
    { particulars: "Monthly Fee", fees: "19000" },
  ];

  const nine = [
    { particulars: "Admission Fee", fees: "10000" },
    { particulars: "Registration Fee", fees: "10000" },
    { particulars: "Annual Fee", fees: "10000" },
    { particulars: "Maintenance", fees: "10000" },
    { particulars: "Monthly Fee", fees: "19500" },
  ];

  const ten = [
    { particulars: "Admission Fee", fees: "10000" },
    { particulars: "Registration Fee", fees: "10000" },
    { particulars: "Annual Fee", fees: "10000" },
    { particulars: "Maintenance", fees: "10000" },
    { particulars: "Monthly Fee", fees: "20000" },
  ];

  const eca = [
    { particulars: "P.G. - UKG", fees: "3000" },
    { particulars: "One - Five", fees: "7500" },
    { particulars: "Six - Ten", fees: "7500" },
  ];

  const stationery = [
    { particulars: "P.G. - UKG", fees: "2500" },
    { particulars: "One - Three", fees: "3500" },
    { particulars: "Four - Seven", fees: "4000" },
    { particulars: "Eight - Ten", fees: "4500" },
  ];

  const transport = [{ particulars: "Yearly", fees: "30000, 36000, 42000" }];

  const uniform = [{ particulars: "Tie, Belt, Diary & ID Card", fees: "2000" }];

  return (
    <div>
      {/* <p className="text-4xl text-center my-20 font-extrabold"></p> */}
      <h2 class="font-heading text-center my-20 font-bold tracking-tight text-green-950 text-3xl sm:text-5xl">
      Fee Structure
              </h2>
      <div className="container mx-auto bg-orange-50 mb-8">
      <div className="grid md:grid-cols-3 sm:grid-cols-1">
        <FeeTable name="Stationery" fees={stationery} />
        <FeeTable name="ECA" fees={eca} />
        <FeeTable name="Transportation" fees={transport} />
        <FeeTable name="Uniform" fees={uniform} />
        <FeeTable name="P.G./Nursery" fees={pg} />
        <FeeTable name="L.K.G." fees={lkg} />
        <FeeTable name="U.K.G." fees={ukg} />
        <FeeTable name="One" fees={one} />
        <FeeTable name="Two" fees={two} />
        <FeeTable name="Three" fees={three} />
        <FeeTable name="Four" fees={four} />
        <FeeTable name="Five" fees={five} />
        <FeeTable name="Six" fees={six} />
        <FeeTable name="Seven" fees={seven} />
        <FeeTable name="Eight" fees={eight} />
        <FeeTable name="Nine" fees={nine} />
        <FeeTable name="Ten" fees={ten} />
      </div>
      </div>
    </div>
  );
};

export default Fees;
