import React from "react";
import YogaImg from "../images/Yoga-at-Panini.jpg";

const MemoryPower = () => {
  return (
    <div className="flex flex-col items-center rounded-xl border border-green-500 px-4">
      <p className="text-3xl text-green-800 font-bold my-10">
        Memory Power Technique
      </p>
      <div className="overflow-hidden my-1 rounded-lg">
        <img src={YogaImg} alt=""           className="object-cover h-72 w-96 hover:scale-105"
 />
      </div>
      <p className="text-justify my-12">
        <span className="text-green-500">Panini School</span> believes that
        children learn best when they understand how to learn! We develop
        children’s learning power by making the teaching environment more
        interesting and relevant that helps them from being passive learners to
        active and independent learners. We have twenty minutes reading and
        learning session for student’s everyday as an alternative period and has
        been named ‘My Dear Time’ .
      </p>
    </div>
  );
};

export default MemoryPower;
