import React from "react";
import Library from "../Library";
import MemoryPower from "../MemoryPower";
import Cafeteria from "../Cafeteria";
import Medical from "../Medical";
import Transport from "../Transport";

const OurSpecialities = () => {
  return (
    <div className="my-20">
      <p className="text-5xl text-center text-green-900 font-bold my-10">
        Our Specialities
      </p>
      <div className="container mx-auto px-8">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-8">
          <Library />
          <Medical />
          <MemoryPower />
          <Cafeteria />
          <Transport />
        </div>
      </div>
    </div>
  );
};

export default OurSpecialities;
