import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SchoolImg from "../images/school.jpg";
import Img1 from "../images/image1.JPG";
import Img2 from "../images/image2.JPG";
import Img3 from "../images/image3.JPG";
import Img4 from "../images/image4.JPG";
import Img5 from "../images/image5.JPG";
import Img6 from "../images/image6.JPG";

export default function MyCarousel() {
  return (
    <Carousel
      autoPlay={true}
      interval={4000}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      transitionTime={1500}
      stopOnHover={false}
    >
      <div>
        <img src={SchoolImg} alt="School" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img1} alt="1" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img2} alt="2" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img3} alt="3" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img4} alt="4" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img5} alt="5" className="h-200 w-full object-cover"/>
      </div>
      <div>
        <img src={Img6} alt="6" className="h-200 w-full object-cover"/>
      </div>
    </Carousel>
  );
}
