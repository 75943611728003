const EventItems = [
  {
    event_title: "Vaccination Program",
    event_description:
      "Vaccination drive against Dadura/Rubela facilitated by Gothatar Heath Post .We express gratitude to all participants of the campaign. #PaniniSchool #PaniniScouts #ProgressiveLearningCentre #paninimontessori #paninians #innovative #education #learning #EducationForAll #children",
    event_date: { year: "2024", month: "Mar", day: "06" },
    event_photos: [
      require("../images/events/vaccination1.jpg"),
      require("../images/events/vaccination2.jpg"),
      require("../images/events/vaccination3.jpg"),
      require("../images/events/vaccination4.jpg"),
    ],
  },
  {
    event_title: "Chitlang Diary",
    event_description:
      "Chitlang Diary- Our  Grade 3 and 4 students carved a wonderful Night-Out moments.Thank you Gautam Holidays Uttam Gautam sir for wonderful management. #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success",
    event_date: { year: "2024", month: "Mar", day: "06" },
    event_photos: [
      require("../images/events/chitlang1.jpg"),
      require("../images/events/chitlang2.jpg"),
      require("../images/events/chitlang3.jpg"),
      require("../images/events/chitlang4.jpg"),
    ],
  },
  {
    event_title: "UKG Graduation Ceremony",
    event_description:
      "Some snaps taken during U.K.G Graduation Ceremony 2080!! #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success",
    event_date: { year: "2024", month: "Feb", day: "22" },
    event_photos: [
      require("../images/events/graduation1.jpg"),
      require("../images/events/graduation2.jpg"),
      require("../images/events/graduation3.jpg"),
      require("../images/events/graduation4.jpg"),
    ],
  },
  {
    event_title: "7th Foundation Day",
    event_description:
      "7th Foundation Day, Still shots! #PaniniSchool #PaniniMontessori #PaniniScouts #ProgressiveLearningCentre #education #Children #learning #success #paninians #innovative #educationforall #schoolactivity #education #learning #success #foundationday",
    event_date: { year: "2024", month: "Feb", day: "21" },
    event_photos: [
     require( "../images/events/foundationday1.jpg"),
     require( "../images/events/foundationday2.jpg"),
     require( "../images/events/foundationday3.jpg"),
     require( "../images/events/foundationday4.jpg"),
    ],
  },
  {
    event_title: "Saraswoti Pooja 2080",
    event_description:
      "Saraswoti Pooja 2080",
    event_date: { year: "2024", month: "Feb", day: "14" },
    event_photos: [
      require("../images/events/saraswotipuja1.jpg"),
      require("../images/events/saraswotipuja2.jpg"),
      require("../images/events/saraswotipuja3.jpg"),
      require("../images/events/saraswotipuja4.jpg"),
    ],
  },
  {
    event_title: "Handwritring Competition",
    event_description:
      "Congratulations to our students on their selection to the provincial-level handwriting competition! Best wishes as they gear up for the upcoming round.",
    event_date: { year: "2023", month: "Dec", day: "30" },
    event_photos: [
      require("../images/events/handwriting1.jpg"),
      require("../images/events/handwriting2.jpg"),
      require("../images/events/handwriting3.jpg"),
      require("../images/events/handwriting4.jpg"),
    ],
  },
  {
    event_title: "Taekwondo Belt Upgrades",
    event_description:
      "A martial artist has to take responsibility for himself and accept the consequences of his own doing– Bruce Lee Taekwondo Belt Upgrades at Panini: Our students are excelling in self defence. #taekwondo",
    event_date: { year: "2023", month: "Dec", day: "25" },
    event_photos: [
      require("../images/events/taekwondo1.jpg"),
      require("../images/events/taekwondo2.jpg"),
      require("../images/events/taekwondo3.jpg"),
      require("../images/events/taekwondo4.jpg"),
    ],
  },
  {
    event_title: "Montessori Dry Picnic",
    event_description:
      "Montessori magic on the hilltop! Dry picnic delights at Pilot Baba Hill Temple with our tiny explorers. #GradeLKGandUKG #PaniniSchool #ProgressiveLearningCentre #paninimontessori #PaniniScouts #paninians #innovative #education #learning #EducationForAll #children",
    event_date: { year: "2023", month: "Dec", day: "19" },
    event_photos: [
      require("../images/events/picnic1.jpg"),
      require("../images/events/picnic2.jpg"),
      require("../images/events/picnic3.jpg"),
      require("../images/events/picnic4.jpg"),
    ],
  },
  {
    event_title: "Five Days of Tour",
    event_description:
      "Five Days of Tour ! Everlasting Days of Memory !!",
    event_date: { year: "2023", month: "Dec", day: "09" },
    event_photos: [
      require("../images/events/tour1.jpg"),
      require("../images/events/tour2.jpg"),
      require("../images/events/tour3.jpg"),
      require("../images/events/tour4.jpg"),
    ],
  },
  
];

export default EventItems;
